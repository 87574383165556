//open popup
$('.open-popup').click(function (e) {
    e.preventDefault();
    let popid = $(this).attr('href');
    $(popid).fadeIn();
    //Force flex
    $(popid).css('display', 'flex');
    //fix body to stop scrolling
    $('body').addClass('popup-open');
});

let closePopup = function (elem) {
    elem.parents('.main-popup').fadeOut();
    $('body').removeClass('popup-open');
};

//close popup
$('.main-popup__close').click(function () {
    closePopup($(this));
});
$('#unsubscribe_cancel').click(function () {
    closePopup($(this));
});