import "@fortawesome/fontawesome-free/css/all.min.css";
import Translator from "bazinga-translator";
import "dropzone/dist/basic.css";
import Dropzone from "dropzone/dist/dropzone";

require("../../css/converter/app.scss");
require("../../css/converter/common.scss");

global.$ = global.jQuery = require("jquery");

let domain = $("body").data("domain");
if (domain === "safeconv") {
  require("../../css/converter/global/safeconv/_styles.scss");
}

require("bootstrap");

window.Translator = Translator;

window.Dropzone = Dropzone;
Dropzone.autoDiscover = false;

$(document).ready(() => {
  updateFooter();
  positionScroll();
  if (document.getElementsByClassName("counter").length) {
    refreshConversionCounter();
    setInterval(() => {
      refreshConversionCounter();
    }, 3000);
  }
});
$(window).resize(() => {
  updateFooter();
});

// Menu toggle
$(".main-header__burger").click((e) => {
  e.preventDefault();
  $("html, body")
    .animate({ scrollTop: 0 }, 100)
    .promise()
    .then(() => {
      $("body").toggleClass("menu-open");
    });
});

// Sets offset of social block in the footer
function updateFooter() {
  let leftPosition =
    $("#footerLinkBox1").offset().left -
    $(".main-footer__wrapper").offset().left;
  $(".main-footer__social").css({
    left: leftPosition + "px",
  });
}

// Homepage: Position the scrollbar to focus on the uploader
// PaymentPage: position the scrollbar to focus on the payment form
// EmailPage: position the scrollbar to focus on the email form
function positionScroll() {
  let position = 0;
  if (window.innerHeight < 740) {
    position += 84;
  }
  if (document.getElementsByClassName("upload-step2").length) {
    position += 150;
  }
  $(window).scrollTop(position);
}

let conversionCounter = 1000 + Math.floor(Math.random() * Math.floor(50));
let counterElement = document.getElementsByClassName("counter")[0];

function refreshConversionCounter() {
  conversionCounter += Math.floor(Math.random() * Math.floor(3));
  counterElement.getElementsByTagName("span")[0].innerHTML = "0";
  counterElement.getElementsByTagName("span")[1].innerHTML = conversionCounter
    .toString()
    .charAt(0);
  counterElement.getElementsByTagName("span")[2].innerHTML = conversionCounter
    .toString()
    .charAt(1);
  counterElement.getElementsByTagName("span")[3].innerHTML = conversionCounter
    .toString()
    .charAt(2);
  counterElement.getElementsByTagName("span")[4].innerHTML = conversionCounter
    .toString()
    .charAt(3);
}

// Conversion supported list
$("#btn-files").click(function () {
  $(".type-section .format-files").css("display", "flex");
  $(".type-section .format-images").hide();
  $(".type-section .format-audios").hide();
  $(".type-section .format-videos").hide();
});
$("#btn-images").click(function () {
  $(".type-section .format-files").hide();
  $(".type-section .format-images").css("display", "flex");
  $(".type-section .format-audios").hide();
  $(".type-section .format-videos").hide();
});
$("#btn-audios").click(function () {
  $(".type-section .format-files").hide();
  $(".type-section .format-images").hide();
  $(".type-section .format-audios").css("display", "flex");
  $(".type-section .format-videos").hide();
});
$("#btn-videos").click(function () {
  $(".type-section .format-files").hide();
  $(".type-section .format-images").hide();
  $(".type-section .format-audios").hide();
  $(".type-section .format-videos").css("display", "flex");
});

$(".scrollup").click(function () {
  $("html, body").animate({ scrollTop: 0 }, 600);
  return false;
});

//Page specific includes
require("./popup");
